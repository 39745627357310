import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import CreateProductFeature from './createProductFeature';
import useShops from '../hooks/useShops';

const deleteFeature = async _id => axios.delete(`/featuredProducts/${_id}`)

export default function ProductFeatures() {

    const [shops] = useShops();
    const [features, setFeatures] = useState([]);
    const [featureRefreshFlag, setFeatureRefreshFlag] = useState();
    const refreshFeatures = useCallback(_ => setFeatureRefreshFlag(!featureRefreshFlag), [featureRefreshFlag]);
    const [selectedShopId, setSelectedShopId] = useState()
    const [editingProductFeature, setEditingProductFeature] = useState()
    const [showDeleted, setShowDeleted] = useState(false);

    useEffect(_ => {
        axios.get('/featuredProducts').then(resp => resp.data).then(setFeatures)
    }, [featureRefreshFlag])

    if (!shops || !shops[0]) return <div>Loading...</div>;

    return (
        <div className="m-5">
            <FormControl as="select" value={selectedShopId || 'Select a Shop'} onChange={e => setSelectedShopId(e.target.value)}>
                <option key={-1} value={''}>--ALL--</option>
                {shops.map(shop => <option key={shop._id} value={shop._id}>{shop.name}</option>)}
            </FormControl>
            {
                <Button disabled={!selectedShopId} className="my-2 py-2 font-weight-bold" block onClick={_ => setEditingProductFeature({})}>
                    {!selectedShopId && 'Select a Shop to'} Create New Feature
                </Button>
            }
            <Table>
                <thead>
                    <tr>
                        <th>Shop ID</th>
                        <th>Created</th>
                        <th>Product</th>
                        <th>Type</th>
                        <th>Sales</th>
                        <th>Impressions</th>
                        <th>
                            <FormCheck
                                id="toggle-switch"
                                type="switch"
                                variant="danger"
                                checked={showDeleted}
                                onChange={(e) => setShowDeleted(e.currentTarget.checked)}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {features.filter(f => showDeleted || (f.active && f.views < f.impressions)).filter(f => !selectedShopId || f.shopId === selectedShopId).map(feature =>
                        <tr key={feature._id}>
                            <td>{feature.shopId}</td>
                            <td>{new Date(feature.createdDate).toLocaleDateString()}</td>
                            <td>{feature.productName || feature.productId}</td>
                            <td>{feature.type}</td>
                            <td>${feature.sales.concat(feature.preorderSales).reduce((total, product) => total + product.quantity*product.price, 0)}</td>
                            <td>{feature.views}/{feature.impressions}</td>
                            <td>
                                { feature.active && feature.views < feature.impressions ?
                                    <Button variant="outline-danger" onClick={_ => deleteFeature(feature._id).then(refreshFeatures)}>Delete</Button> :
                                        feature.views < feature.impressions ?
                                            <span className="text-danger">Deleted</span> :
                                            <span className="text-success">Complete</span>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {editingProductFeature && <CreateProductFeature shopId={selectedShopId || shops[0]._id} onClose={_ => setEditingProductFeature(null)} afterSave={refreshFeatures} />}
        </div>
    )
}
