
import { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';

const generateSearchResult = (product, searchStr) => {
    product['searchIndex'] = product.name.toUpperCase().search(searchStr.toUpperCase());
    if (product.sku && product['searchIndex'] < 0) {
        product['searchIndex'] = product.sku.toUpperCase().search(searchStr.toUpperCase());
    }
    if (product.locationName && product.locationName === searchStr) product['searchIndex'] = 0;
    return product;
}

export default function CreateProductFeature({ shopId, onClose, afterSave }) {
    const [allProducts, setAllProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedProduct, setSelectedProduct] = useState();
    const [impressions, setImpressions] = useState(0);
    const [type, setType] = useState('');

    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(_ => {
        axios.get(`/shop/${shopId}/products`).then(resp => setAllProducts(resp.data))
    }, [shopId])


    const [adImageUrl, setAdImageUrl] = useState(null);
    const onDropAdImage = useCallback(acceptedFiles => {
        setDisableSubmit(true)
        let file = acceptedFiles[0];
        setAdImageUrl(URL.createObjectURL(file));

        let imgBody = new FormData();
        imgBody.append('featureBannerImage', file)
        axios.post('/assets/featureBannerImage', imgBody , {headers: { 'content-type': 'multipart/form-data' }})
            .then(({data}) => setAdImageUrl(data?.Location))
            .catch(e => console.error('Error Uploading Image: ', e))
            .then(_ => setDisableSubmit(false));
    }, []);

    const { getRootProps: getBannerRootProps, getInputProps: getBannerInputProps, isDragActive: isBannerDragActive } = useDropzone({ onDrop: onDropAdImage, accept: 'image/*' })




    const saveFeature = _ => {
        axios.post('/featuredProducts', {
            shopId,
            productId: selectedProduct?._id,
            productName: selectedProduct?.name,
            impressions,
            type,
            adImageUrl,
            views: 0,
            active: true
        }).then(resp => resp.data).then(_ => {
            afterSave();
            onClose();
        }).catch(e => toast.error(e.response?.data))
    }

    let products = allProducts.map(p => generateSearchResult(p, search))
        .filter(p => p.searchIndex >= 0)
        .sort((p, q) => p.searchIndex >= q.searchIndex );

    return <Modal show={true} size="xl" onHide={onClose}>
        <Modal.Header closeButton={true}>
            <h2>{ shopId } { selectedProduct && `- ${selectedProduct.name}`}</h2>
        </Modal.Header>
        
            <Modal.Body>
                <Form.Label>Impressions:</Form.Label>
                <Form.Control type="number" onChange={e => setImpressions(parseInt(e.target.value))}/>
                <Form.Label>Type:</Form.Label>
                <div key="type">
                    {/* <Form.Check type="radio" name="typeRadio" onChange={_ => setType("banner")} label="Kiosk Banner"/> */}
                    <Form.Check type="radio" name="typeRadio" onChange={_ => setType("ribbon")} label="Kiosk Ribbon"/>
                    <Form.Check type="radio" name="typeRadio" onChange={_ => setType("popup")} label="Kiosk Popup"/>
                    <Form.Check type="radio" name="typeRadio" onChange={_ => setType("preorderBanner")} label="Pre-Order Banner"/>
                    <Form.Check type="radio" name="typeRadio" onChange={_ => setType("preorderRibbon")} label="Pre-Order Ribbon"/>
                    <Form.Check type="radio" name="typeRadio" onChange={_ => setType("preorderPopup")} label="Pre-Order Popup"/>
                </div>
                <div className='my-2'>
                    { getBannerRootProps && (type === "banner" || type === "preorderBanner") &&
                        <Col md="12" className="dropzone" {...getBannerRootProps()}>
                            <input {...getBannerInputProps()} />
                            {
                                isBannerDragActive ?
                                    <p>Drop image here ...</p> :
                                    <div>
                                        {!adImageUrl ? <p>Upload Banner Image Here</p>
                                            : <img className='dropzone-preview' alt="uploaded" src={adImageUrl} />}
                                    </div>
                            }
                        </Col>
                    }
                </div>
                <Row>
                    <Form.Control placeholder="Search" onChange={e => setSearch(e.target.value)}/>
                    <ListGroup className="mt-2" style={{height: '300px', overflowY: 'scroll'}}>
                        { products.map( (p, idx) =>
                            <ListGroup.Item key={idx} onClick={_ => selectedProduct?._id === p?._id ? setSelectedProduct(null) : setSelectedProduct(p)} variant={selectedProduct?._id === p._id ? "primary" : ""} style={{cursor: "pointer"}}>
                                {p.name}
                            </ListGroup.Item>
                        ) }
                    </ListGroup>
                    {!products.length && <span className="text-danger">No Products Avalable for Selected Shop</span>}
                </Row>
            </Modal.Body>
        { !!impressions && type &&
            <Modal.Footer>
                <Button disabled={disableSubmit} className="float-right" onClick={saveFeature}>Save</Button>
            </Modal.Footer>
        }
    </Modal>
}
